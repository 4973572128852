import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import ModalWrapper from 'Components/layout/modals/ModalWrapper';
import ModalBody from 'Components/layout/modals/ModalBody';
import ModalTitle from 'Components/layout/modals/ModalTitle';
import NewType from 'Components/trainer/measurementsConfig/NewType';

export default class TrainerModalMeasurementsConfigNewType extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        data: PropTypes.object,
    };
    static defaultProps = {
        isOpen: false,
        onClose: null,
        data: null,
    };
    state = {};

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        return parseQueryToObject(search, true);
    }

    render() {
        const { location, history, isOpen, onClose, data } = this.props;
        const queryObject = this.getQueryConfig();

        return (
            <ModalWrapper
                location={location}
                history={history}
                isOpen={isOpen}
                onClose={onClose}
                styleVariant="default"
            >
                <StyledComponent className="trainer-modal-new-type" styles={require('./styles')}>
                    <ModalBody styleVariant='default'>
                        <ModalTitle title='Nowy typ pomiarów' />
                        <NewType
                            location={location}
                            history={history}
                            data={data}
                            onConfirm={onClose}
                            userId={queryObject.userId}
                        />
                    </ModalBody>
                </StyledComponent>
            </ModalWrapper>
        );
    }
}
