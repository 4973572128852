import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TRAINER_DASHBOARD, TRAINER_ADEPTS_MANAGE } from 'Consts/routes';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/trainer/measurementsConfig/Editor';
import { TAB_MEASUREMENTS } from 'Components/pages/admin/AdeptData/component';
import MeasurementConfigNewType from 'Components/trainer/modals/MeasurementsConfigNewType';

export const TAB_DATA = 'data';

export default class TrainerMeasurementsConfigManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        measurementConfig: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };

    state = {
        newType: false,
    };

    static defaultProps = {};

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        return parseQueryToObject(search, true);
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        actions.single({ id: queryObject.userId });
    }

    render() {
        const { measurementConfig, location, history } = this.props;
        const { newType } = this.state;
        const queryObject = this.getQueryConfig();

        return (
            <StyledComponent
                className="trainer-measurements-config-manage"
                styles={require('./styles')}
            >
                <Page
                    elementMode={true}
                    data={measurementConfig}
                >
                    {measurementConfig && measurementConfig.data && (
                        <PageContent
                            breadcrumbs={[{
                                to: withVariables(TRAINER_DASHBOARD.path),
                                label: 'Dashboard',
                            }, {
                                to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: queryObject.userId }, { tab: TAB_DATA }),
                                label: 'Adept',
                            }, {
                                to: withVariables(TRAINER_ADEPTS_MANAGE.path, { id: queryObject.userId }, { tab: TAB_MEASUREMENTS }),
                                label: 'Pomiary',
                            }]}
                        >
                            <TabNavigation
                                location={location}
                                history={history}
                                headline="Zarządaj pomiarami"
                                controls={[{
                                    visible: true,
                                    key: 'newType',
                                    label: 'Nowy typ pomiarów',
                                    onClick: () => this.setState({ newType: true }),
                                    style: 'gradient',
                                    layout: 'wide',
                                }, {
                                    visible: true,
                                    key: 'return',
                                    label: 'Powrót do pomiarów',
                                    onClick: () => history.push(
                                        withVariables(TRAINER_ADEPTS_MANAGE.path, { id: queryObject.userId }, { tab: TAB_MEASUREMENTS }),
                                    ),
                                    style: 'gradient',
                                    layout: 'wide',
                                }]}
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={measurementConfig.data}
                                            userId={queryObject.userId}
                                            config={measurementConfig.data}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>
                <MeasurementConfigNewType
                    location={location}
                    history={history}
                    isOpen={Boolean(newType)}
                    onClose={() => this.setState({ newType: false })}
                    data={{ newType }}
                />
            </StyledComponent>
        );
    }
}

